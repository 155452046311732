import React from "react";
import Layout from "../../components/layout";
import medDevHeader from "../../assets/images/med-dev-header.svg";
import medDev from "../../assets/images/med-dev.svg";
import { Row, Col } from "react-bootstrap";
import NewNavbar from "../../components/navbar";

class BlogDetailPage extends React.Component {
  render() {
    return (
      <Layout pageInfo={{ pageName: "med-dev" }}>
        <NewNavbar />
        <div className="page-container">
          <Row className="mt100">
            <Col
              className={"inline-pages-content-box service-detail"}
              md={{ span: 12 }}
            >
              <Row className="d-flex align-items-center">
                <Col md={6}>
                  <p className="title">MedDev Intelligence</p>
                  <p>
                    Risklick’s data-driven analysis guides trial designers
                    through the decision-making processes for projects in the
                    pre-clinical phase. We provide accurate risk estimates that
                    increase the probability of success (POS). Failure to
                    account for these risks could delay or even prevent smooth
                    and rapid entry into the market.
                  </p>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img src={medDevHeader} className="header-img" />
                </Col>
              </Row>

              <Row>
                <Col className="text-center">
                  {/* <p className="mb-0">
                    A well-designed preclinical and clinical strategy is key to
                    the success of your product. However, clinical development
                    processes require an accurate estimation of risks to
                    increase their probability of success (POS) and ensure a
                    smooth market entry.
                  </p>
                  <img src={medDev} className="mt50 mb50" /> */}
                  <p>
                    Our MedDev Intelligence increases POS by deploying
                    real-world data to support portfolio management and
                    decision-making processes. MedDev Intelligence generates a
                    report that highlights and analyzes all relevant
                    POS-associated risks in your clinical development plan,
                    including:
                    <ul>
                      <li>The competitive landscape</li>
                      <li>Market opportunities</li>
                      <li>Safety profile of your chosen compounds</li>
                      <li>POS estimate</li>
                      <li>
                        A historical overview of the clinical research landscape
                      </li>
                    </ul>
                    With MedDev Intelligence, you can foreground safety in your
                    decision-making processes and confidently enter the market.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Layout>
    );
  }
}

export default BlogDetailPage;
